import React from 'react'
import Layout from '../components/layout'

export default function muchAdoNews1() {
    return (
        <Layout>
            <div style={{padding: "108px 20px 20px 10px", width: 400, margin: "0 auto"}}>
                <h1>Messina Messenger</h1>

                <h2>Troops Return from War</h2>

                <p>Family and friends gathered in the Messina Square early this morning to welcome the troops home from war. The crowd held signs and flags to congratulate the soldiers on their victory.  
                General Don Pedro gives the credit for their triumph to a young Florentine named Claudio. 
                “It was because of this young man’s bravery and quick thinking that we were able to return home safely,” the General declared. 
                Other soldiers returning to Messina are Benedick of Padua and Don John, stepbrother of General Don Pedro.
                All citizens of Messina are invited to a celebratory banquet in honor of the victorious soldiers to be held tomorrow evening at sunset on the Fountain of Orion Piazza. The event is sponsored by Messina Mayor Leonato, his daughter, Hero, and his niece, Beatrice.</p>
            </div>
        </Layout>
    )
}
